window.HBMIN = (function () {
  'use strict';

  // Public
  var module = window.HBM;

  module.initAjaxSecured = function ($body, $elements) {
    // Load value from local storage (if possible)
    if (window.localStorage && window.localStorage.getItem('hbmin-ajax-secured')) {
      module.ajaxSecured = JSON.parse(window.localStorage.getItem('hbmin-ajax-secured'));
    }

    $elements.on('click', function (event) {
      event.preventDefault();

      module.handleAjaxSecured(!module.ajaxSecured, $body, $elements);
    });

    // Init buttons and body.
    module.handleAjaxSecured(module.ajaxSecured, $body, $elements);
  };

  module.handleAjaxSecured = function (newAjaxSecured, $body, $elements) {
    module.ajaxSecured = newAjaxSecured;

    if (window.localStorage) {
      window.localStorage.setItem('hbmin-ajax-secured', JSON.stringify(module.ajaxSecured));
    }

    if (module.ajaxSecured === false) {
      $elements.addClass('active');
      $body.removeClass('ajax-secured');
    }
    if (module.ajaxSecured === true) {
      $elements.removeClass('active');
      $body.addClass('ajax-secured');
    }
  };

  module.refreshWorkerStatus = function ($element) {
    if (!document.hidden) {
      $.get('/admin/worker/status', function (response) {
        $element.show().text(response['running'] + '/' + response['queued'] + '/' + response['delayed']);
        window.setTimeout(function () {
          module.refreshWorkerStatus($element);
        }, 3000);
      });
    }
    else {
      window.setTimeout(function () {
        module.refreshWorkerStatus($element);
      }, 3000);
    }
  };

  return module;

})();
