$(document).ready(function ($, HBMIN, ClipboardJS) {
  'use strict';

  // Works in combination with AjaxAuthenticationListener.php
  $(document).ajaxError(function (event, jqXHR) {
    if ((jqXHR.status === 401) || (jqXHR.status === 403)) {
      if (confirm('Sie sind nicht mehr eingeloggt. Soll die Seite neu geladen werden?')) {
        window.location.reload();
      }
    }
  });

  var $body = $('body');

  HBMIN.initAjaxLinks($body);
  HBMIN.initAjaxButtons($body);
  // HBMIN.initAjaxSecured($body, $('#ajax-secured-toggle'));
  HBMIN.handleAjaxSecured(false, $body, $('#ajax-secured-toggle'));
  HBMIN.initDateTimePicker($body);

  $('[data-card-collapsible]').hbm_initCollapsibleCards();
  $body.hbm_initToggables();

  $body
    .on('dragenter dragstart dragend dragleave dragover drag drop', '#hbmin-aside', function (event) {
      event.preventDefault();
    })
    .on('dragover', '#hbmin-aside', function (event) {
      $(this).addClass('droppable');
    })
    .on('dragleave', '#hbmin-aside', function (event) {
      $(this).removeClass('droppable');
    })
    .on('drop', '#hbmin-aside-drop', function (event) {
      var url = $(this).attr('data-url');

      var item = event.originalEvent.dataTransfer.items[0] || null;
      if (item && (item.kind === 'string') && (item.type === 'text/plain')) {
        item.getAsString(function (s) {
          if (confirm('Infos für "' + s + '" anzeigen?')) {
            window.location = url + '?search=' + encodeURIComponent(s);
          }
          else {
            $(this).removeClass('droppable');
          }
        });
      }
    });

  $('.copy-to-clipboard').tooltip({
    trigger: 'manual',
    title: 'Kopiert!'
  });

  var clipboard = new ClipboardJS('.copy-to-clipboard');

  clipboard.on('success', function (e) {
    $(e.trigger).tooltip('show');

    window.setTimeout(function () {
      $(e.trigger).tooltip('hide');
    }, 1000);
  });

}(jQuery, HBMIN, ClipboardJS));
